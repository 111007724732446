import React from 'react';
import ImageNos from '../../../assets/images/imagem-sobre-nos.png';

import './text-image.scss';

const TextImage = () => (
  <>
    <section id="sobre-nos" className="container">
      <div className="text-nos">
        <p>
          A Sedafio nasceu com a missão de transformar felicidade e sonhos em
          estampas, prezando pelo atendimento e satisfação dos nossos clientes.
          <br></br>
          <br></br>
          Criada em 2018 por dois sócios de longa data, a empresa vem tomando
          forma aos poucos e criando credibilidade e confiabilidade no mercado.
          Ainda que parceiros há muito tempo, pouco tinham trabalhado no ramo de
          estamparia. Um deles já teve empresa de estamparia silkscreen e já
          sabia o quão era desafiador os negócios voltados para a personalização
          de produtos. Oportunidades à vista, com a bagagem cheia de
          experiências e reabastecidos de disposição, optaram por enfrentar esse
          desafio numa área de atuação extremamente disputada por ótimas
          empresas.
          <br></br>
          <br></br>
          Atualmente, com uma equipe bem enxuta e com um maquinário de alto
          desempenho, buscamos gerar valor agregado ao serviço de impressão e
          sublimação de qualidade, oferecendo o melhor negócio para os clientes
          parceiros. Sabemos da grande exigência de qualidade que o mercado
          procura e estamos prontos para conceder o melhor atendimento que o
          cliente merece.
        </p>
      </div>

      <div className="image-nos">
        <img src={ImageNos} alt="img-nos" />
      </div>
    </section>
  </>
);

export default TextImage;
