import React from 'react';
import Mission from '../../../assets/images/missao.png';
import Vision from '../../../assets/images/visao.png';
import Values from '../../../assets/images/valores.png';

import './cards.scss';

const Cards = () => (
  <>
    <section id="cards" className="container">
      <div className="card">
        <div className="block">
          <img src={Mission} alt="img-1" />
          <h1>Missão</h1>
          <p>
            Construir um laço sólido com o ramo de atuação, buscando melhorias
            constantes para entregar satisfação ao consumidor.
          </p>
        </div>
        <div className="block">
          <img src={Vision} alt="img-2" />
          <h1>Visão</h1>
          <p>
            Tonar-se referência na transformação de sonhos em forma de tecidos.
          </p>
        </div>
        <div className="block">
          <img src={Values} alt="img-3" />
          <h1>Valores</h1>
          <p>
            Sustentabilidade, Lealdade, Respeito, Sentimento de Dono, e
            Excelência.
          </p>
        </div>
      </div>
    </section>
  </>
);

export default Cards;
