import React from 'react';

import { SEO } from '../../components/seo';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import TextImage from './text-image';
import Cards from './cards';

import '../../reset.css';

const SobreNos = () => (
  <>
    <SEO title="Sobre Nós" />
    <Header title="Sobre Nós" />
    <TextImage />
    <Cards />
    <Footer />
  </>
);

export default SobreNos;
